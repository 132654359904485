import React from 'react'

const Footer = () => {
  return (
    <>
    <div class="row p-4">
    <p>
        <b class="primary-text">
            Distribution
        </b>
    </p>
    <div class="col-4">
        <h4><b>50k+</b></h4>
        Active wallets
    </div>
    <div class="col-4">
        <h4><b>200k+</b></h4>
        Resolved Issues
    </div>
    <div class="col-4">
        <h4><b>100+</b></h4>
        Contributors
    </div>
    <p class="mt-4">
        Ready to resolve your blockchain issues?<br/>
        We are here to provide help.

    </p>
   
</div>
<div>
<a href="/connect">
<button class="btn primary-btn">
    Get Started
</button>

</a>

<p class="py-4">
<i class="fab fa-facebook"></i>
<i class="fab fa-github"></i>
<i class="fab fa-twitter"></i>
<i class="fab fa-telegram"></i>
</p>
<p class="py-4">
   <small><b>COIN MERGE © 2024 All rights reserved.</b></small> 
</p>


</div>
</>
  )
}

export default Footer