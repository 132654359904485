import React from 'react'

const Container = () => {
  return (
    <div>
         <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                <>

                    
                    <div class="card primary-bg feature-card">
                        <div class="card-header">
                            <i class="redirect fas fa-external-link-alt float-end"></i>
                        </div>
                        <div class="card-body">
                            <img src="/assets/secure.png" />
                            <p>
                                Migration
                            </p>
                        </div>
                    </div>
                </>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                    <>
    
                        
                        <div class="card primary-bg feature-card">
                            <div class="card-header">
                                <i class="redirect fas fa-external-link-alt float-end"></i>
                            </div>
                            <div class="card-body">
                                <img src="/assets/secure.png" />
                                <p>
                                    Validate
                                </p>
                            </div>
                        </div>
                    </>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                        <>
        
                            
                            <div class="card primary-bg feature-card">
                                <div class="card-header">
                                    <i class="redirect fas fa-external-link-alt float-end"></i>
                                </div>
                                <div class="card-body">
                                    <img src="/assets/case.png" />
                                    <p>
                                        Restore
                                    </p>
                                </div>
                            </div>
                        </>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                            <>
            
                                
                                <div class="card primary-bg feature-card">
                                    <div class="card-header">
                                        <i class="redirect fas fa-external-link-alt float-end"></i>
                                    </div>
                                    <div class="card-body">
                                        <img src="/assets/case.png" />
                                        <p>
                                            Staking Review
                                        </p>
                                    </div>
                                </div>
                            </>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                <>
                
                                    
                                    <div class="card primary-bg feature-card">
                                        <div class="card-header">
                                            <i class="redirect fas fa-external-link-alt float-end"></i>
                                        </div>
                                        <div class="card-body">
                                            <img src="/assets/app.png" />
                                            <p>
                                                Claim Reflection
                                            </p>
                                        </div>
                                    </div>
                                </>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                    <>
                    
                                        
                                        <div class="card primary-bg feature-card">
                                            <div class="card-header">
                                                <i class="redirect fas fa-external-link-alt float-end"></i>
                                            </div>
                                            <div class="card-body">
                                                <img src="/assets/app.png" />
                                                <p>
                                                AI AUTOMATED TRADING
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    </div>
                              <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                <>
                
                                    
                                    <div class="card primary-bg feature-card">
                                        <div class="card-header">
                                            <i class="redirect fas fa-external-link-alt float-end"></i>
                                        </div>
                                        <div class="card-body">
                                            <img src="/assets/app.png" />
                                            <p>
                                                Whitelist
                                            </p>
                                        </div>
                                    </div>
                                </>
                                </div>

                                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                        <>
                        
                                            
                                            <div class="card primary-bg feature-card">
                                                <div class="card-header">
                                                    <i class="redirect fas fa-external-link-alt float-end"></i>
                                                </div>
                                                <div class="card-body">
                                                    <img src="/assets/case.png" />
                                                    <p>
                                                        Airdrop
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                            <>
                            
                                                
                                                <div class="card primary-bg feature-card">
                                                    <div class="card-header">
                                                        <i class="redirect fas fa-external-link-alt float-end"></i>
                                                    </div>
                                                    <div class="card-body">
                                                        <img src="/assets/app.png" />
                                                        <p>
                                                            Bridge
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                <>
                                
                                                    
                                                    <div class="card primary-bg feature-card">
                                                        <div class="card-header">
                                                            <i class="redirect fas fa-external-link-alt float-end"></i>
                                                        </div>
                                                        <div class="card-body">
                                                            <img src="/assets/app.png" />
                                                            <p>
                                                                Claim
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                                </div>

                                                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                    <>
                                    
                                                        
                                                        <div class="card primary-bg feature-card">
                                                            <div class="card-header">
                                                                <i class="redirect fas fa-external-link-alt float-end"></i>
                                                            </div>
                                                            <div class="card-body">
                                                                <img src="/assets/secure.png" />
                                                                <p>
                                                                    Rectification
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    </div>

                                                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                        <>
                                        
                                                            
                                                            <div class="card primary-bg feature-card">
                                                                <div class="card-header">
                                                                    <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                </div>
                                                                <div class="card-body">
                                                                    <img src="/assets/secure.png" />
                                                                    <p>
                                                                        Recovery
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        </div>

                                                        <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                            <>
                                            
                                                                
                                                                <div class="card primary-bg feature-card">
                                                                    <div class="card-header">
                                                                        <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <img src="/assets/case.png" />
                                                                        <p>
                                                                            Harvest my Stakings
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            </div>

                                                            <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                <>
                                                
                                                                    
                                                                    <div class="card primary-bg feature-card">
                                                                        <div class="card-header">
                                                                            <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                        </div>
                                                                        <div class="card-body">
                                                                            <img src="/assets/case.png" />
                                                                            <p>
                                                                               Withdrawal
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                </div>

                                                                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                    <>
                                                    
                                                                        
                                                                        <div class="card primary-bg feature-card">
                                                                            <div class="card-header">
                                                                                <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                            </div>
                                                                            <div class="card-body">
                                                                                <img src="/assets/app.png" />
                                                                                <p>
                                                                                    Staking pool
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    </div>

                                                                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                        <>
                                                        
                                                                            
                                                                            <div class="card primary-bg feature-card">
                                                                                <div class="card-header">
                                                                                    <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                </div>
                                                                                <div class="card-body">
                                                                                    <img src="/assets/app.png" />
                                                                                    <p>
                                                                                       Review my NFTs
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        </div>

                                                                        <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                            <>
                                                            
                                                                                
                                                                                <div class="card primary-bg feature-card">
                                                                                    <div class="card-header">
                                                                                        <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                    </div>
                                                                                    <div class="card-body">
                                                                                        <img src="/assets/secure.png" />
                                                                                        <p>
                                                                                            Restore Tokens
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            </div>

                                                                            <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                                <>
                                                                
                                                                                    
                                                                                    <div class="card primary-bg feature-card">
                                                                                        <div class="card-header">
                                                                                            <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                        </div>
                                                                                        <div class="card-body">
                                                                                            <img src="/assets/secure.png" />
                                                                                            <p>
                                                                                                Update my Portfolios
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                </div>

                                                                                <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                                    <>
                                                                    
                                                                                        
                                                                                        <div class="card primary-bg feature-card">
                                                                                            <div class="card-header">
                                                                                                <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                            </div>
                                                                                            <div class="card-body">
                                                                                                <img src="/assets/case.png" />
                                                                                                <p>
                                                                                                    Purchase Tokens
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    </div>

                                                                                    <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                                        <>
                                                                        
                                                                                            
                                                                                            <div class="card primary-bg feature-card">
                                                                                                <div class="card-header">
                                                                                                    <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                                </div>
                                                                                                <div class="card-body">
                                                                                                    <img src="/assets/app.png" />
                                                                                                    <p>
                                                                                                       My NFTs
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        </div>

                                                                                        <div class="col-lg-4 col-md-6 col-sm-12 my-2">
                                                                                            <>
                                                                            
                                                                                                
                                                                                                <div class="card primary-bg feature-card">
                                                                                                    <div class="card-header">
                                                                                                        <i class="redirect fas fa-external-link-alt float-end"></i>
                                                                                                    </div>
                                                                                                    <div class="card-body">
                                                                                                        <img src="/assets/app.png" />
                                                                                                        <p>
                                                                                                            Others
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            </div>
            </div>
           
    </div>
  )
}

export default Container