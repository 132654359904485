import React from 'react'
import WalletConnectBtn from './button';
import Typewriter from 'typewriter-effect';

 
const Header = () => {
  return (
    <>
    <div>
         <h2>
               <strong> COIN MERGE</strong>
            </h2>
            
           <h4> Decentralized to  <Typewriter className='d-inline'
                options={{
                  strings: ['Staking', 'Node', 'Analytics','NFT'],
                  autoStart: true,
                  loop: true,
                }}
/>
 with Chain Protocol
 </h4>
            
            <p>
                The protocol establishes a remote connection using a Bridge server.
            </p>
            <p>
                <>
              <button class="btn btn-action" data-bs-toggle='modal' data-bs-target='#convince'>
                Connect Wallet
              </button> 
            </>
            
        </p>
            <div class="text-dark">
                <script type="text/javascript" src="https://files.coinmarketcap.com/static/widget/coinMarquee.js"></script><div id="coinmarketcap-widget-marquee" coins="1,1027,825" currency="USD" theme="light" transparent="false" show-symbol-logo="true"></div>
            </div>
            <p class="my-4">
                <b  class="primary-text">Quick Start</b>
            </p>





            <div className='modal fade' id='convince'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content bg-dark'>
          <div className='modal-header h3 text-light'>
              
          </div>
          <div className='modal-body'>
            
            <p className='text-center text'>
              <b>Connect wallet, Select a network and hit "Merge" below</b>
              <WalletConnectBtn/>
            </p>
          </div>

          <div className='modal-footer'>
            <h4 className=''>
               
            </h4>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    </>
  )
}

export default Header