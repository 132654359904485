import logo from './logo.svg';
import React from 'react';
import 'bootstrap/dist/js/bootstrap.js'
import './App.css';
import Header from './components/Header';
import Container from './components/Container';
import Footer from './components/Footer';
import { configureChains, createConfig, WagmiConfig,} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { avalanche, bsc, mainnet,arbitrum,dogechain,optimism,polygon,harmonyOne, goerli } from 'wagmi/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import WalletConnectBtn from './components/button';


const projectId = '2c515028cc183de99fa6a655231e348b'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet,bsc,arbitrum,avalanche,dogechain,optimism,polygon,harmonyOne],
  [alchemyProvider({ apiKey: 'hP6CpRTZybyLdjDaD_PE4qZD2ZlMgkQF' }), publicProvider()],
)
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Metamask',
        shimDisconnect: true,
      },
    }),
    //new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'wagmi',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
   
  ],
  publicClient,
  webSocketPublicClient,
})

function App() {
  return (
      <>
      <WagmiConfig config={config}>
        <div className='' style={{backgroundImage: 'linear-gradient(to bottom, #000000 10%, #15161f 100%)', backgroundRepeat: 'no-repeat', backgroundSize:'auto'}}>
          <div className='container py-4'>
            <center>
              <Header/>
              <Container/>
              <Footer/>

            </center>

          </div>

        </div>
        </WagmiConfig>
      </>
  );
}

export default App;
